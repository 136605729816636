import { Component, OnInit } from '@angular/core';
import { Global } from './globales';
import {
  Router,
  NavigationError,
  Event,
  NavigationEnd,
  NavigationCancel,
  NavigationStart
} from '@angular/router';
import { ServicioOverlayCargando } from './utilerias/overlay-cargando.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Movilidad';

  constructor(
    private router: Router,
    private overlay: ServicioOverlayCargando
  ) {
    this.router.events.subscribe(routerEvent => {
      this.checkRouterEvent(routerEvent);
    });
  }

  checkRouterEvent(routerEvent: Event): void {
    if (routerEvent instanceof NavigationStart) {
      this.overlay.abrirCargando();
    }

    if (
      routerEvent instanceof NavigationEnd ||
      routerEvent instanceof NavigationCancel ||
      routerEvent instanceof NavigationError
    ) {
      this.overlay.cerrarCargando();
    }
  }
  ngOnInit(): void {}

}
