import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MatTableDataSource, MAT_DIALOG_DATA, MatSort, MatDialog } from '@angular/material';
import { RastreoClienteModel } from 'src/app/entidades/rastreo-cliente';
import * as moment from 'moment';
import * as _ from 'underscore';
import { concat, filter, now } from 'lodash';
import { CierreDespachoOperativoMesMesComponent } from '../cierre-despacho-operativo-mes/cierre-despacho-operativo-mes.component';

@Component({
    selector: 'app-cierre-despacho-operativo-data',
    templateUrl: './cierre-despacho-operativo-data.component.html',
    styleUrls: ['./cierre-despacho-operativo-data.component.scss']
})
export class CierreDespachoOperativoDataComponent implements OnInit {
    fuenteDatos: MatTableDataSource<any> = new MatTableDataSource([]);
    @ViewChild(MatSort) ordenador: MatSort;
    columnasMostradas = ['mesNum', 'periodo', 'promPrev', 'serviciosActivos', 'aTiempo', 'nivelServicio', 'objetivo', 'promAct'];
    rastreosNuevos: RastreoClienteModel[];
    diasDeTolerancia: number;

    constructor(
        @Inject(MAT_DIALOG_DATA) public object: { rastreos: RastreoClienteModel[], rastreoss: RastreoClienteModel[], objetivo: number, yearF: number, diasTolerancia: number },
        private ventana: MatDialog) {
    }

    ngOnInit(): void {

        //Obtenemos los rasteos nuevos
        this.rastreosNuevos = this.object.rastreos;
        this.diasDeTolerancia = this.object.diasTolerancia;

        //Obtenemos las propiedades del mes incluiido el numero del mes, nombre del mes, numero del mes en string, y los datos del mes
        const months = _.chain(this.object.rastreos)
            .groupBy(c => moment(c.fechaEntrega).format('MM'))
            .map((x, y) => {
                return {
                    monthNumber: y,
                    monthName: `${moment(y, 'MM').locale('Es-MX').format('MMMM')}`,
                    monthNumberStr: String(Number(y) - 1),
                    numeroMes: Number(y),
                    data: x
                }
            })
            .sortBy(c => Number(c.monthNumber)).value();

        //Obtenemos los valores de los meses anteriores    
        const monthsPrev = _.chain(this.object.rastreoss)
            .groupBy(c => moment(c.fechaEntrega).format('MM'))
            .map((x, y) => {
                return {
                    monthNumber: y,
                    monthName: `${moment(y, 'MM').locale('Es-MX').format('MMMM')}`,
                    data: x
                }
            })
            .sortBy(c => Number(c.monthNumber)).value();

        const monthValuesPrev = _.chain(monthsPrev.map(c => c.data))
            .map(c => {
                return (c.filter(d => moment(d.fechaEnvioSAP).diff(d.fechaEntrega, 'days') >  this.diasDeTolerancia).length / c.length) * 100;
            }).value();

        
        const avarageMonths = _.chain(months.map(c => c.data)).sortBy(d => d).map(c => {
            return (c.filter(d => moment(d.fechaEnvioSAP).diff(d.fechaEntrega) > this.diasDeTolerancia).length / c.length) * 100;
        }).value();
        const avarageMonth = _.reduce(avarageMonths, (memo, v) => memo + v, 0) / avarageMonths.length;

        const monthsModel = _.chain(months).map((month, key) => {

            return {
                'mesNum': month.monthNumber,
                'periodo': month.monthName,
                'promAct': `${((month.data.filter(d => moment(d.fechaEnvioSAP).diff(d.fechaEntrega, 'days') > this.diasDeTolerancia).length / month.data.length) * 100).toFixed(2)} %`,
                'promPrev': `${Number(month.numeroMes) > monthValuesPrev.length ? '' : monthValuesPrev[month.monthNumberStr].toFixed(2)} %`,
                'servicios': month.data.length,
                'aTiempo': month.data.filter(d => moment(d.fechaEnvioSAP).diff(d.fechaEntrega, 'days') <= this.diasDeTolerancia).length,
                'fueraTiempo': `${((month.data.filter(d => moment(d.fechaEnvioSAP).diff(d.fechaEntrega, 'days') > this.diasDeTolerancia).length / month.data.length) * 100).toFixed(2)} %`,
                'objetivo': `${this.object.objetivo.toFixed(2)} %`
            };
        }).value();
        monthsModel.pop();

        const lastMonth = _.chain(months).last().value();

        const weeks = _.chain(lastMonth.data)
            .groupBy(c => moment(c.fechaEntrega).week())
            .map((x, y) => {
                return {
                    weekNumber: `${Number(y)}`,
                    numeroSemana: Number(y),
                    weekNumberStr: String(Number(y) - 1),
                    indice: "",
                    data: x
                }
            })
            .sortBy(c => Number(c.weekNumber)).value();
        months.pop();

        const lastWeek = _.chain(monthsPrev.filter(c => Number(moment(c.monthNumber).format('MM')) == Number(moment(new Date()).format('MM')))).value();


        // const Lastweeks = _.chain(lastWeek[0].data)
        //     .groupBy(c => moment(c.fechaEntrega).week())
        //     .map((x, y) => {
        //         return {
        //             weekNumber: `${Number(y)}`,
        //             numeroSemana: Number(y),
        //             weekNumberStr: String(Number(y) - 1),
        //             data: x
        //         }
        //     })
        //     .sortBy(c => Number(c.weekNumber)).value();

        let incremento = 0;
        weeks.forEach(t => {
             t.indice = `"`+ incremento + `"`;
             incremento = incremento +1;
        });
        

        // const weekValuesPrev = _.chain(Lastweeks.map(c => c.data))
        //     .map(c => {
        //         return (c.filter(d => moment(d.fechaEnvioSAP).diff(d.fechaEntrega, 'days') > this.diasDeTolerancia).length / c.length) * 100;
        //     }).value();


        const weeksModel = _.chain(weeks).map(month => {
            return {
                'mesNum': month.weekNumber,
                'periodo': `W${month.weekNumber}`,
                'promPrev': '0.00',
                // `${Number(month.numeroSemana) > weekValuesPrev.length ? '' : weekValuesPrev[month.indice].toFixed(2)} %`,
                'servicios': month.data.length,
                'aTiempo': month.data.filter(d => moment(d.fechaEnvioSAP).diff(d.fechaEntrega, 'days') <= this.diasDeTolerancia).length,
                'fueraTiempo': `${((month.data.filter(d => moment(d.fechaEnvioSAP).diff(d.fechaEntrega, 'days') > this.diasDeTolerancia).length / month.data.length) * 100).toFixed(2)} %`,
                'objetivo': `${this.object.objetivo.toFixed(2)} %`,
                'promAct': `${((month.data.filter(d => moment(d.fechaEnvioSAP).diff(d.fechaEntrega, 'days') > this.diasDeTolerancia).length / month.data.length) * 100).toFixed(2)} %`,
            };
        }).value();

        this.fuenteDatos = new MatTableDataSource(monthsModel.concat(weeksModel));
        this.fuenteDatos.sort = this.ordenador;
    }

    abrirFormaMes(mesNum): void {

        if(Number(mesNum) > 12 ){
            //no abro ventana
        }else{
            
            //OBTENGO MES ACTUAL
            var mesActual =  moment(new Date(now())).format('MM')
            var mesActN = Number(mesActual)
            
            if( Number(mesNum) > mesActN){

            }else{
                const forma = this.ventana.open(CierreDespachoOperativoMesMesComponent, {
                    data: { rastreos: this.rastreosNuevos, objetivo: this.object.objetivo, messNum: mesNum , yearF: this.object.yearF, diasTolerancia: this.diasDeTolerancia},
                    panelClass: 'form-container',
                    width: '70vw'
                });
            }
        }       
    }

}
